import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

const primaryStyledButton = (isDisabled?: boolean) => css`
  color: ${({ theme }) => theme.color.text.white};
  ${() =>
          isDisabled
                  ? css`
                    background-color: ${({ theme }) => theme.color.background.button.disabled};
                    pointer-events: none;
                  `
                  : css`
                    background-color: ${({ theme }) => theme.color.background.button.main};
                  `}
`

const secondaryStyledButton = (isDisabled?: boolean) =>
  isDisabled
    ? css`
            border: 1px solid #D8BEFF;
            background-color: #F4F4FB;
            color: #D8BEFF
    `
    : css`
            background-color: #FFFFFF;
            border: 1px solid ${({ theme }) => theme.color.background.button.main};
    `

const StyledButton = styled.button<{
  buttonStyle?: 'secondary' | 'main'
  isDisabled?: boolean
  styledFragment?: FlattenSimpleInterpolation
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  width: max-content;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  outline: none !important;
  border: none;
  font-family: 'Trap', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  transition: all .3s;
  
  ${({ buttonStyle, isDisabled }) => {
    switch (buttonStyle) {
      case 'main':
        return primaryStyledButton(isDisabled)
      case 'secondary':
        return secondaryStyledButton(isDisabled)
      default:
        return primaryStyledButton(isDisabled)
    }
  }}

  ${({ styledFragment }) => styledFragment};
`

export default StyledButton
