import React from 'react';
import './App.css';
import Layout from 'Layout'
import Landing from 'Widgets/Landing'
function App() {
  return (
    <Layout>
      <Landing />
    </Layout>
  );
}

export default App;
