import React from 'react'
import { ButtonWrapper, LogoWrapper, TextWrapper, Wrapper } from './styled'
import Logo from 'assets/static/Logo.svg'
import Text from 'Components/UiKit/Text'
import Button from 'Components/UiKit/Button'
import { css, useTheme } from 'styled-components'
const Welcome = () => {

  const theme = useTheme()

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={Logo} alt='logotype' />
      </LogoWrapper>
      <TextWrapper>
        <Text
          size={68}
          weight={600}
          lineHeight={90}
          font={'Trap'}
          styledFragment={css`
            @media screen and (max-width: 1000px) {
              font-size: 40px;
              line-height: 50px;
            }

            @media screen and (max-width: 500px) {
              font-size: 30px;
              line-height: 35px;
            }
          `}
        >
          Empowering Green Progress: Revolutionizing Crowdfunding with Gecco Tokens
        </Text>
        <Text
          size={24}
          weight={500}
          lineHeight={38}
          color={theme.color.text.secondary}
          styledFragment={css`
            text-align: center;
            
            @media screen and (max-width: 1000px) {
              font-size: 20px;
              line-height: 28px;
            }

            @media screen and (max-width: 500px) {
              font-size: 16px;
              line-height: 20px;
            }
          `}
        >
          Join the Future of Sustainable Financing:
        </Text>
        <Text
          size={24}
          weight={500}
          lineHeight={38}
          color={theme.color.text.secondary}
          styledFragment={css`
            text-align: center;
            max-width: 730px;

            @media screen and (max-width: 1000px) {
              font-size: 20px;
              line-height: 28px;
            }

            @media screen and (max-width: 500px) {
              font-size: 16px;
              line-height: 20px;
            }
          `}
        >
          Utilize Gecco Tokens to Drive Modernization, Emission Reduction,
          and Carbon Credit Generation through Crowdfunding Excellence.
        </Text>
      </TextWrapper>
      <ButtonWrapper>
        <Button>Open Platform</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default Welcome
