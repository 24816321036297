import React from 'react'
import Welcome from './Components/Welcome'
import { Wrapper } from './styled'

const Landing = () => {
  return (
    <Wrapper>
      <Welcome />
    </Wrapper>
  )
}

export default Landing
