import React, { ReactNode } from 'react'
import { createGlobalStyle, DefaultTheme, ThemeProvider } from 'styled-components'
import { ThemeFragment, theme, primaryThemeFragment } from 'stylesheets/styledVars'
interface LayoutProps {
  children: ReactNode | ReactNode[]
}

const GlobalStyle = createGlobalStyle<{
  style: DefaultTheme
  themeFragment: ThemeFragment
}>`
  body {
    background: var(--bg-color);
    overflow-x: hidden;
    font-family: 'Helvetica Neue', serif;
    margin: 0;
  }

  :root {
    --bg-color: ${({ themeFragment }) => themeFragment.bgColorPrimary};
  }
`
const Layout = (props: LayoutProps) => {
  const { children } = props
  return (
    <>
      <GlobalStyle style={theme} themeFragment={primaryThemeFragment} />
      <ThemeProvider theme={Object.assign(theme, primaryThemeFragment)}>
        {children}
      </ThemeProvider>
    </>
  )
}

export default Layout
