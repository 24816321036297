import { StyledVars } from './types'
export const theme: StyledVars = {
	color: {
		background: {
			main: '#F4F6F9',
			secondary: '#FFFFFF',
			button: {
				main: '#000',
				disabled: '#7c98ff'
			},
		},
		text: {
			main: '#000000',
			secondary: '#666',
			white: '#fff'
		},
	}
}

export type ThemeFragment = {
	bgColorPrimary: string,
}

export const primaryThemeFragment: ThemeFragment = {
	bgColorPrimary: '#D7FFE9',
}


